
<template>
  <div>
    <div class="Passwoerd">
      <div class="Band">
        <img src="../draw/img/liaojiele.png" alt="" class="Mook" />
        <img
          src="../draw/img/liaojie.png"
          alt=""
          class="Sum"
          @click="qianwang()"
        />
      </div>
      <div class="Usen">
        <div class="Sand">
          <div class="Poss">
            <input
              type="text"
              class="Rand"
              placeholder="请输入手机号"
              v-model="phone"
            />
            <div class="Moos">
              <input
                type="text"
                class="Cand"
                style="margin-top: 0px"
                placeholder="请输入验证码"
                v-model="number"
              />
              <div class="Pands">
                <p v-if="Kand" @click="huoqu()">获取验证码</p>
                <p v-if="!Kand">{{ num }}</p>
              </div>
            </div>
            <div class="Good" @click="denglu()">
              <img src="../draw/img/zhuce.png" alt="" class="Bodin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { sendSms, codelogin } from '../../api/index'
export default {
  data() {
    return {
      num: 60,
      Kand: true,
      phone: '',
      number: '',
      ticket: '',
      randstr: '',
      isweixn: false,
      Usernumber: '',
      count: '',
      Ponys: true,
      day: '',
      hour: '',
      minute: '',
      second: '',
      rid: '',
      store_id:''
    }
  },
  mounted() {
    this.rid = this.$route.query.rid
    this.store_id = this.$route.query.store_id
    console.log(this.rid)
  },
  methods: {
    huoqu() {
      this.ticket = Math.floor(Math.random() * (9999 - 1000)) + 1000
      this.randstr = Math.floor(Math.random() * (9999 - 1000)) + 1000
      this.sendSmsPath()
    },
    daojishi() {
      var that = this
      var sass = setInterval(() => {
        that.num--
        if (that.num == 0) {
          that.Kand = true
          that.num = 60
          clearInterval(sass)
        }
      }, 1000)
    },
    denglu() {
      this.codelogin()
    },
    qianwang() {
      window.location.href = 'https://www.hxnc.cc/'
    },
    onec() {
      setTimeout(() => {
        this.isweixn = false
      }, 100)
    },
    async codelogin() {
      let data = {
        rid: this.rid,
        store_id:this.store_id,
        phone: this.phone,
        keyCode: this.number
      }
      let res = await codelogin(data)
      if (res.data.code == 200) {
        this.$router.push({
          path: '/Skippage',
          query: { scene: 'Home', pageName: 'index' }
        })
        console.log(res)
      } else {
        this.$toast(res.data.message)
      }
    },

    async sendSmsPath() {
      let data = {
        phone: this.phone,
        type1: 1,
        ticket: this.ticket,
        randstr: this.randstr
      }
      let res = await sendSms(data)
      if (res.data.code == 200) {
        this.daojishi()
        this.Kand = false
        this.$toast(res.data.message)
      } else {
        this.$toast(res.data.message)
      }
    }
  }
}
</script>


<style lang="scss" scoped >
.Mook {
  width: 100%;
  height: 50vh;
}
.Passwoerd {
  height: 100vh;
  background: #0aab65;
}

.Band {
  position: relative;
}
.Sum {
  position: absolute;
  width: 250px;
  top: 45%;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.moban {
  width: 80vw;
  height: 50vh;
  margin-left: 10vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 40px;
  background: rgb(252, 252, 252);
}
.moban2 {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
}
.Usen {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Sand {
  height: 380px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.Poss {
  width: 90vw;
  border-radius: 30px;
  background: #ffffff;
}
.Rand {
  width: 85%;
  height: 80px;
  background: #f2f2f2;
  border: 0;
  margin: 30px 30px 30px 30px;
  padding: 0 0 0 40px;
  border-radius: 30px;
  font-size: 30px;
}
.name {
  margin-top: 20px;
  text-align: center;
  font-weight: 900;
  font-size: 40px;
}
.Rander {
  text-align: center;   
  color: #999999;
  margin-top: 20px;
}
.mask-box {
  position: fixed;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  display: block;
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.huodong {
  width: 30vw;
}
.Rids {
  text-align: center;
  line-height: 100px;
  font-size: 30px;
  font-weight: 900;
}
.Loder {
  border-top: 1px solid #cccccc;
}
.Cand {
  width: 45%;
  height: 80px;
  background: #f2f2f2;
  border: 0;
  margin: 30px 0 30px 30px;
  padding: 0 0 0 40px;
  border-radius: 30px 0 0 30px;
  font-size: 30px;
  border-right: 2px solid #e0e0e0;
}
.Pands {
  width: 40%;
  height: 80px;
  line-height: 80px;
  background: #f2f2f2;
  text-align: center;
  border-radius: 0 30px 30px 0;
  font-size: 30px;
  color: #0aab65;
}
.Good {
  display: flex;
  justify-content: center;
}

.Bodin {
  width: 90%;
  height: 120px;
}

.Urand {
  text-align: center;
  font-size: 28px;
  color: #ddff58;
}
.Moos {
  display: flex;
}
</style>